<template>
  <section class="about__page" style="height: 100%;">
    <nav
      v-if="this.$route.meta.subpage !== true"
      class="container about__page_nav d-flex justify-center align-center pt-3 col-12 col-md-8"
    >
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'cards' }">
          Cáritas
        </router-link>
      </v-col>
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'sobre_pipam' }">
          PIPAM
        </router-link>
      </v-col>
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'sobre_assessoria' }">
          Assessoria Técnica
        </router-link>
      </v-col>
      <v-col class="about__page_nav-link">
        <router-link :to="{ name: 'sobre_cadastro' }">
          O Cadastro
        </router-link>
      </v-col>
    </nav>
    <transition>
      <router-view />
    </transition>
    <ScrollTop />
  </section>
</template>

<script>
import ScrollTop from "../components/ScrollTop.vue";
export default {
  components: { ScrollTop },
  created() {},
};
</script>

<style scoped>
a {
  color: #c92f05;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #c92f05;
}
</style>
